import moment, { Moment } from 'moment-timezone';
import { NumberUtils } from './number-utils';
import { TimezoneOption } from '@models/timezone/timezone-option.model';

export class DateUtils {
  static getDateRangeAtTimezone(
    startDate: Date | undefined,
    endDate: Date | undefined,
    startTime: string,
    endTime: string,
    timeZone: string,
  ): { startDate?: Date; endDate?: Date } {
    if (!timeZone || timeZone === '') {
      timeZone = moment.tz.guess();
    }

    let startDateFull = null;
    let endDateFull = null;

    if (startDate) {
      const dateOnlyString = this.dateOnlyToString(startDate);

      startDateFull = moment.tz(`${dateOnlyString} ${startTime}`, timeZone);
    }
    if (!endDate && endTime && endTime !== '') {
      endDate = startDate;
    }
    if (endDate) {
      const dateOnlyString = this.dateOnlyToString(endDate);

      endDateFull = moment.tz(`${dateOnlyString} ${endTime}`, timeZone);
    }

    return {
      startDate: startDateFull?.toDate(),
      endDate: endDateFull?.toDate(),
    };
  }

  static dateOnlyToString(date: Date): string | null {
    if (!date) {
      return null;
    }

    const monthNumber = date.getMonth() + 1;
    const month = NumberUtils.padStart(monthNumber, 2, '0');

    const dayNumber = date.getDate();
    const day = NumberUtils.padStart(dayNumber, 2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
  }

  static getUserTimezoneOption(): TimezoneOption {
    const timezone = moment.tz.guess();
    const offset = moment.tz(timezone).utcOffset();

    return new TimezoneOption({
      name: timezone,
      offset: offset,
    });
  }

  static getDateAfterBusinessDays(businessDays: number): Moment {
    const currentDate = moment();

    let businessDaysCount = 0;
    while (businessDaysCount < businessDays) {
      currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDaysCount++;
      }
    }

    return currentDate;
  }

  static isAtLeastXBusinessDaysFuture(date: Date, businessDays: number) {
    const currentDate = moment();
    const futureDate = moment(date);

    if (futureDate.isSameOrBefore(currentDate)) {
      return false;
    }

    let businessDaysCount = 0;
    while (businessDaysCount < businessDays) {
      currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDaysCount++;
      }
    }

    return currentDate.isSameOrBefore(futureDate, 'day');
  }

  static formatDateDay(date: Date, currentLang?: string) {
    const day = date.getDay();
    switch (day) {
      case 1:
        return currentLang === 'en' ? 'Mon' : 'Mo';
      case 2:
        return currentLang === 'en' ? 'Tue' : 'Di';
      case 3:
        return currentLang === 'en' ? 'Wed' : 'Mi';
      case 4:
        return currentLang === 'en' ? 'Thu' : 'Do';
      case 5:
        return currentLang === 'en' ? 'Fri' : 'Fr';
      case 6:
        return currentLang === 'en' ? 'Sat' : 'Sa';
      default:
        return currentLang === 'en' ? 'Sun' : 'So';
    }
  }
}
